<template>
    <v-col cols="12" class="col-md-12 col-lg-9">
        <v-card class="mx-auto">
            <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                <v-toolbar-title class="text-section-title">Quote Details</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row class="ma-0 mb-10" align="center" justify="center">
                    <v-switch inset label="Metallic" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.metallic"></v-switch>
                    <v-switch inset label="Pearl" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.pearl"></v-switch>
                    <v-switch inset label="Aluminium" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.aluminium"></v-switch>
                    <v-switch inset label="Lg Vehicle" class="ma-3" :true-value="'L'" :false-value="'D'"
                        v-model="optModel.vehicle_type"></v-switch>
                    <v-switch inset label="XL Vehicle" class="ma-3" :true-value="'XL'" :false-value="'D'"
                        v-model="optModel.vehicle_type"></v-switch>
                </v-row>
                <quote-arch-cards @itemsUpdate="model.items = $event" :optModel="optModel" :loading="apiLoading"
                    @totalModelUpdate="$emit('totalUpdate', $event)" :panelItems="itemsModel ? itemsModel: null"/>
                <add-products @productsUpdate="model.products = $event"
                    @priceUpdate="$emit('prodPriceUpdate', $event)" :lists="model && model.products ? model.products: []"/>
                <add-parts @partsUpdate="model.parts = $event" :parts="partsList" :lists="model && model.parts ? model.parts: []"/>
                <add-comment @modelUpdate="model = {...model, ...$event}" :data="commentModel"/>
                <v-row class="ma-0 my-3" justify="center" align="center">
                    <v-btn type="submit" color="primary" @click="saveGenerateQuote" :disabled="loading">
                        {{ loading ? 'Loading..' : 'Save and Generate Quote' }}
                        <v-icon right v-if="!loading">mdi-download</v-icon>
                        <v-progress-circular indeterminate color="primary" v-if="loading"
                            :size="20" class="ml-2"></v-progress-circular>
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
    emits: ['totalUpdate', 'prodPriceUpdate'],
    components: {
        quoteArchCards: () => import("./QuoteCards"),
        addProducts: () => import("./AddProducts"),
        addParts: () => import("./AddPart"),
        addComment: () => import("./AddComment"),
    },
    data: (vm) => ({
        quoteScanId: vm.$route.params.quoteScanId,
        model: {
            scan_id: vm.$route.params.scanId,
            products: [],
            assessor: vm.$helpers.getCurData('curUser').name || '',
        },
        partsList: [],
        optModel: {
            aluminium: 0,
            metallic: 0,
            pearl: 0,
            vehicle_type: 'D'
        },
        commentModel: null,
        itemsModel: null,
        loading: false,
        apiLoading: false,
    }),
    created() {
        this.apiLoading = true;
        this.getQuoteEvaluation(this.quoteScanId).then(resp => this.getQuote(resp));
    },
    methods: {
        ...mapActions(['createQuote', 'searchQuote', 'getQuoteEvaluation']),

        getQuote(quoteEval) {
            this.searchQuote(this.quoteScanId).then(resp => {
                if(!_.isEmpty(resp)) {
                    this.itemsModel = resp.items;
                    this.model = resp;
                    this.model.client_id = quoteEval.client ? quoteEval.client.id : '';
                    if (quoteEval.client.id !== resp.client_id) this.model.products = [];
                    this.commentModel = {manual_check: resp.manual_check, assessor_comments: resp.assessor_comments};
                    Object.keys(this.optModel).map(item => this.optModel[item] = this.model[item] || item === 'vehicle_type' ? 'D' : null );
                } else this.model = { scan_id: this.quoteScanId, client_id: quoteEval.client ? quoteEval.client.id : '' };
                this.apiLoading = false;
            }).catch(err => {
                this.model = { scan_id: this.quoteScanId, client_id: quoteEval.client ? quoteEval.client.id : '' };
                this.apiLoading = false;
            });

          // Check if clientData has parts before mapping over it
          if (quoteEval.client && quoteEval.client.parts) {
            this.partsList = quoteEval.client.parts.map((v) => {
              return { "text": v, "value": v }
            });
          }
        },
        async saveGenerateQuote() {
            this.loading = true;
            const self = this;
            const reqData = this.$helpers.cloneData(this.model);
            reqData['items'] = {};
            reqData['scan_id'] = this.quoteScanId;
            await Object.keys(this.model.items).map(key => {
                if (!this.model.items[key].hasLargeVariant ||this.optModel.vehicle_type == this.model.items[key].vehicle_type) reqData['items'][key] = this.model.items[key];
            });
            this.createQuote({...reqData, ...this.optModel}).then(resp => {
                self.loading = false;
                self.$router.push(`/quotes/${resp.id}/invoice`);
            }).catch(err => this.loading = false);
        }
    }
}
</script>

<style>

</style>